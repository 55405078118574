import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ls from 'local-storage';
import axios from 'axios';
import { PropTypes } from 'prop-types';
import { getIpData } from '../helpers/getIpData';
import { pelotonPaymentCADUrl, pelotonPaymentUSDUrl } from '../environment';
import { apiConfig } from '../apiConfig';
import Cookie from 'js-cookie';

const PayMessage = ({ user }) => {
  const history = useHistory();
  const token = ls.get('token');

  const logout = () => {
    axios
      .delete(apiConfig.baseUrl + `/token/delete/${token}/`, {
        auth: apiConfig.auth,
      })
      .finally(() => {
        // we use finally instead of then because we want to logout whether the axios call was successful or not
        ls.clear();
        Cookie.remove('token', {
          domain: '.leftbrainperformance.com',
        });
        history.push('/signin');
      });
  };

  return (
    <Modal
      show={true}
      backdrop="static"
      centered
      style={{ textAlign: 'center' }}
    >
      <Modal.Body>
        <p id="modalTitle">
          Looks like you still need to complete your registration!
        </p>
        <div id="modalActions">
          <Button
            id="payButton"
            variant="primary"
            onClick={() => {
              let ipDetails = getIpData(user.ip_address);
              ipDetails.country === 'CA'
                ? (window.location.href =
                    pelotonPaymentCADUrl + '?param=' + btoa(user.email))
                : (window.location.href =
                    pelotonPaymentUSDUrl + '?param=' + btoa(user.email));
            }}
          >
            Complete Registration
          </Button>
          <a
            id="logout"
            href="#"
            onClick={() => {
              logout();
            }}
          >
            Logout
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p>
          Have you already paid? Let us know so we can fix it, please email{' '}
          <a href="mailto:support@leftbrainperformance.com">
            support@leftbrainperformance.com
          </a>
        </p>
      </Modal.Footer>
    </Modal>
  );
};

PayMessage.propTypes = {
  user: PropTypes.object,
};

export default PayMessage;
