import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { apiConfig } from '../apiConfig';
import UserContext from '../context/UserContext';
import HtmlConverter from '../components/HtmlConverter';
import Alert from 'react-bootstrap/Alert';
import { Col, Row } from 'react-bootstrap';
import ResultPageLoading from '../components/ResultPageLoading';

const ResultPage = () => {
  const { user } = useContext(UserContext);
  const { athleteId, resultId } = useParams();
  const [resultHtml, setResultHtml] = useState(null);
  const [permission, setPermission] = useState(null);
  const [reportUser, setReportUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingOwnResults, setLoadingOwnResults] = useState(false);

  useEffect(() => {
    if (!user) return;

    if (user.type === 'ATHLETE') {
      // if there are athleteId and resultId in the URL, reject because this is
      // the incorrect URL for athlete's own results
      if (athleteId || resultId) {
        setPermission(false);
        setLoading(false);
      } else {
        setPermission(true);
        setReportUser(user);
        setLoadingOwnResults(true);
      }
      return;
    }

    // if this is a coach but there are no athleteId and resultId, they want to look at their own results
    if (user.type === 'COACH' && !athleteId && !resultId) {
      setPermission(true);
      setReportUser(user);
      setLoadingOwnResults(true);
      return;
    }

    // if this coach doesn't have a school, not allowed to look at any users
    if (user.schools_shared_to.length === 0) {
      setPermission(false);
      setLoading(false);
      return;
    }

    // does this coach have permission to this athlete's report?
    axios
      .get(
        `${apiConfig.baseUrl}/users/${athleteId}/checkpermission/${user.id}/`,
        {
          auth: apiConfig.auth,
        }
      )
      .then((res) => {
        setPermission(true);
        setReportUser(res.data);
      })
      .catch(() => {
        // something went wrong, so just block permission
        setPermission(false);
        setLoading(false);
      });
  }, [user, athleteId, resultId]);

  useEffect(() => {
    if (!permission || !reportUser) return;

    // report URL differs for users viewing their own results, vs. coaches viewing athlete results
    let requestUrl = `/users/${reportUser.id}/results/latest/`;
    if (!loadingOwnResults && user.type === 'COACH') {
      requestUrl = `/coachaccess/users/${athleteId}/results/${resultId}/`;
    }

    axios
      .get(`${apiConfig.baseUrl}${requestUrl}`, {
        auth: apiConfig.auth,
      })
      .then((res) => {
        if (res.data['coach_report'] !== undefined)
          setResultHtml(res.data['coach_report']);
        else setResultHtml(res.data['report']);
        setLoading(false);
      })
      .catch(() => {
        console.log('error - no api call response');
        setResultHtml('');
        setLoading(false);
      });
  }, [reportUser, permission, athleteId, resultId, user]);

  const goBack = () => {
    history.back();
  };

  return (
    <div data-testid="results-container" className="page-results">
      {loading ? (
        <ResultPageLoading />
      ) : (
        <>
          {permission ? (
            <>
              {user.type === 'COACH' && reportUser && !loadingOwnResults && (
                <>
                  <div className="mb-2">
                    <a href={'#'} onClick={goBack} className="mb-2">
                      &lt; Back
                    </a>
                  </div>
                  <Alert
                    variant="info"
                    className="coach-view-athlete-info"
                    data-testid="coach-report-athlete-info"
                  >
                    <div className="coach-report-athlete-info-title">
                      Athlete Info
                    </div>
                    <hr />
                    <Row>
                      <div className="col-lg-6">
                        <Row className="mb-2 mb-sm-0">
                          <Col
                            md={4}
                            lg={5}
                            className="coach_report-athlete-info-header"
                          >
                            First Name
                          </Col>
                          <Col md={8} lg={7}>
                            {reportUser.first_name}
                          </Col>
                        </Row>
                        <Row className="mb-2 mb-sm-0">
                          <Col
                            md={4}
                            lg={5}
                            className="coach_report-athlete-info-header"
                          >
                            Last Name
                          </Col>
                          <Col md={8} lg={7}>
                            {reportUser.last_name}
                          </Col>
                        </Row>
                        <Row className="mb-2 mb-sm-0 mb-md-4">
                          <Col
                            md={4}
                            lg={5}
                            className="coach_report-athlete-info-header"
                          >
                            Email
                          </Col>
                          <Col md={8} lg={7}>
                            <a href={`mailto:${reportUser.email}`}>
                              {reportUser.email}
                            </a>
                          </Col>
                        </Row>
                        {reportUser.highschool_team && (
                          <Row className="mb-2 mb-sm-0">
                            <Col
                              md={4}
                              lg={5}
                              className="coach_report-athlete-info-header"
                            >
                              Highschool Team
                            </Col>
                            <Col md={8} lg={7}>
                              {reportUser.highschool_team}
                            </Col>
                          </Row>
                        )}
                        {reportUser.aau_team && (
                          <Row className="mb-2 mb-sm-0">
                            <Col
                              md={4}
                              lg={5}
                              className="coach_report-athlete-info-header"
                            >
                              AAU Team
                            </Col>
                            <Col md={8} lg={7}>
                              {reportUser.aau_team}
                            </Col>
                          </Row>
                        )}
                        {reportUser.travel_team && (
                          <Row className="mb-2 mb-sm-0">
                            <Col
                              md={4}
                              lg={5}
                              className="coach_report-athlete-info-header"
                            >
                              Travel Team
                            </Col>
                            <Col md={8} lg={7}>
                              {reportUser.travel_team}
                            </Col>
                          </Row>
                        )}
                        {reportUser.sports.length > 0 && (
                          <Row className="mb-2 mb-sm-0 mt-md-4">
                            <Col
                              md={4}
                              lg={5}
                              className="coach_report-athlete-info-header"
                            >
                              Sport
                            </Col>
                            <Col md={8} lg={7}>
                              {reportUser.sports[0].name}
                            </Col>
                          </Row>
                        )}
                        {reportUser.position && (
                          <Row className="mb-2 mb-sm-0">
                            <Col
                              md={4}
                              lg={5}
                              className="coach_report-athlete-info-header"
                            >
                              Position
                            </Col>
                            <Col md={8} lg={7}>
                              {reportUser.position.name}
                            </Col>
                          </Row>
                        )}
                      </div>

                      <div className="col-lg-6 mt-md-4 mt-lg-0">
                        <Row className="mb-2 mb-sm-0">
                          <Col
                            md={4}
                            lg={6}
                            className="coach_report-athlete-info-header"
                          >
                            Registered
                          </Col>
                          <Col md={8} lg={6}>
                            {new Date(
                              reportUser.date_joined
                            ).toLocaleDateString()}
                          </Col>
                        </Row>
                        <Row className="mb-2 mb-sm-0">
                          <Col
                            md={4}
                            lg={6}
                            className="coach_report-athlete-info-header"
                          >
                            Completed
                          </Col>
                          <Col md={8} lg={6}>
                            {reportUser.results.length > 0 ? (
                              <>
                                {new Date(
                                  reportUser.results[0].created_datetime
                                ).toLocaleDateString()}
                              </>
                            ) : (
                              <span>n/a</span>
                            )}
                          </Col>
                        </Row>
                        <Row className="mb-2 mb-sm-0">
                          <Col
                            md={4}
                            lg={6}
                            className="coach_report-athlete-info-header"
                          >
                            Shared Before Completion?
                          </Col>
                          <Col md={8} lg={6}>
                            {reportUser.results.length > 0 ? (
                              <>
                                {reportUser.results[0].shared_before_completion
                                  ? 'Yes'
                                  : 'No'}
                              </>
                            ) : (
                              <span>n/a</span>
                            )}
                          </Col>
                        </Row>
                        <Row className="mb-2 mb-sm-0">
                          <Col
                            md={4}
                            lg={6}
                            className="coach_report-athlete-info-header"
                          >
                            Used my referral code?
                          </Col>
                          <Col md={8} lg={6}>
                            {reportUser.coach_referral_coach ? (
                              <>
                                {reportUser.coach_referral_coach.id === user.id
                                  ? 'Yes'
                                  : 'No'}
                              </>
                            ) : (
                              <span>No</span>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </Alert>
                </>
              )}
              {resultHtml ? (
                <HtmlConverter html={resultHtml} />
              ) : (
                <div>No results found.</div>
              )}
            </>
          ) : (
            <Alert variant="danger" data-testid="permission-denied-msg">
              Permission denied.
            </Alert>
          )}
        </>
      )}
    </div>
  );
};

export default ResultPage;
