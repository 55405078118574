import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from 'axios';
import _ from 'lodash';
import { apiConfig } from '../apiConfig';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/pro-duotone-svg-icons';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import CoachPortalBreadcrumbs from './CoachPortalBreadcrumbs';
import CoachPortalSidebarContext from '../context/CoachPortalSidebarContext';
import UserContext from '../context/UserContext';

let sportNameFilter = () => {};
let positionFilter = () => {};
let sharedWithMeFilter = () => {};
let sharedWithMeMobileFilter = () => {};
let assessmentCompleteFilter = () => {};
let assessmentCompleteMobileFilter = () => {};
let usedMyCodeFilter = () => {};
let usedMyCodeMobileFilter = () => {};

const CoachPortalAthleteListing = () => {
  const { user } = useContext(UserContext);
  const { sidebarState } = useContext(CoachPortalSidebarContext);
  const [data, setData] = useState([]);
  const [sportNameFilterVal, setSportNameFilterVal] = useState('');
  const [positionFilterVal, setPositionFilterVal] = useState('');
  const [filterValueSharedWithMe, setFilterValueSharedWithMe] = useState(false);
  const [filterValueAssessmentComplete, setFilterValueAssessmentComplete] =
    useState(false);
  const [filterValueUsedMyCode, setFilterValueUsedMyCode] = useState(false);

  const sortCaratFn = (order) => {
    if (!order) return <FontAwesomeIcon icon={faSort} />;
    else if (order === 'asc') return <FontAwesomeIcon icon={faSortDown} />;
    else if (order === 'desc') return <FontAwesomeIcon icon={faSortUp} />;

    return null;
  };

  const formatMainReportData = (text, mobile = false) => {
    // find "has_report" indicator for use later, and remove from display text
    let has_report = text.indexOf('has_report') >= 0;
    text = text.replace('has_report', '');

    let rows = text.split('\n');
    return (
      rows.length > 0 && (
        <>
          {rows.map((row, index) => (
            <div key={index}>
              {row.indexOf('@') >= 0 ? (
                <a href={`mailto:${row}`}>{row}</a>
              ) : row.indexOf('**Teams**') >= 0 ? (
                <div className="teams-header">Teams:</div>
              ) : (
                row !== 'null' && <>{row}</>
              )}
            </div>
          ))}
          {mobile && has_report && (
            <FontAwesomeIcon
              icon={faFileAlt}
              className="mobile-report-indicator"
            />
          )}
        </>
      )
    );
  };

  const reportDateFormatterFn = (cell, row) => {
    // wrap report data in link to report
    if (row.results_url === '') return;

    return <Link to={row.results_url}>{cell}</Link>;
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      sortCaret: sortCaratFn,
      formatter: (cell) => {
        return formatMainReportData(cell);
      },
    },
    {
      dataField: 'teams',
      text: 'Teams',
      sort: true,
      sortCaret: sortCaratFn,
      formatter: (cell) => {
        return formatMainReportData(cell);
      },
    },
    {
      dataField: 'sport_name',
      text: 'Sport',
      sort: true,
      sortCaret: sortCaratFn,
      filter: textFilter({
        caseSensitive: true,
        getFilter: (filter) => {
          sportNameFilter = filter;
        },
      }),
    },
    {
      dataField: 'position_name',
      text: 'Position',
      sort: true,
      sortCaret: sortCaratFn,
      filter: textFilter({
        getFilter: (filter) => {
          positionFilter = filter;
        },
      }),
    },
    {
      dataField: 'report_date_formatted',
      text: 'Report',
      sort: true,
      sortCaret: sortCaratFn,
      formatter: reportDateFormatterFn,
    },
    {
      dataField: 'is_my_sport',
      text: 'Shared with me (hidden)',
      filter: textFilter({
        getFilter: (filter) => {
          sharedWithMeFilter = filter;
        },
      }),
      classes: 'd-none',
      headerClasses: 'd-none',
    },
    {
      dataField: 'completed_initial_assessment',
      text: 'Completed Initial Assessment (hidden)',
      filter: textFilter({
        getFilter: (filter) => {
          assessmentCompleteFilter = filter;
        },
      }),
      classes: 'd-none',
      headerClasses: 'd-none',
    },
    {
      dataField: 'used_my_code',
      text: 'Used My Code (hidden)',
      filter: textFilter({
        getFilter: (filter) => {
          usedMyCodeFilter = filter;
        },
      }),
      classes: 'd-none',
      headerClasses: 'd-none',
    },
  ];

  const columns_mobile = [
    {
      dataField: 'combined_data',
      text: 'Athlete + Sport',
      sort: true,
      attrs: { style: { position: 'relative' } },
      sortCaret: sortCaratFn,
      filter: textFilter(),
      formatter: (cell) => {
        return formatMainReportData(cell, true);
      },
    },
    {
      dataField: 'is_my_sport',
      text: 'Shared with me (hidden)',
      filter: textFilter({
        getFilter: (filter) => {
          sharedWithMeMobileFilter = filter;
        },
      }),
      classes: 'd-none',
      headerClasses: 'd-none',
    },
    {
      dataField: 'completed_initial_assessment',
      text: 'Completed Initial Assessment (hidden)',
      filter: textFilter({
        getFilter: (filter) => {
          assessmentCompleteMobileFilter = filter;
        },
      }),
      classes: 'd-none',
      headerClasses: 'd-none',
    },
    {
      dataField: 'used_my_code',
      text: 'Used My Code (hidden)',
      filter: textFilter({
        getFilter: (filter) => {
          usedMyCodeMobileFilter = filter;
        },
      }),
      classes: 'd-none',
      headerClasses: 'd-none',
    },
  ];

  const { SearchBar } = Search;

  const rowEventsMobile = {
    onClick: (e, row) => {
      // if user has results, navigate to the results page
      if (row.results_url !== '') {
        location.href = row.results_url;
      }
    },
  };

  const filterControlSharedWithMe = (val) => {
    setFilterValueSharedWithMe(val);
  };

  // filter table by "Shared with me" filter
  useEffect(() => {
    if (!filterValueSharedWithMe) {
      sharedWithMeFilter('');
      sharedWithMeMobileFilter('');
    } else {
      sharedWithMeFilter(true);
      sharedWithMeMobileFilter(true);
    }
  }, [filterValueSharedWithMe]);

  const filterControlAssessmentComplete = (val) => {
    setFilterValueAssessmentComplete(val);
  };

  // filter table by "Assessment Complete" filter
  useEffect(() => {
    if (!filterValueAssessmentComplete) {
      assessmentCompleteFilter('');
      assessmentCompleteMobileFilter('');
    } else {
      assessmentCompleteFilter(true);
      assessmentCompleteMobileFilter(true);
    }
  }, [filterValueAssessmentComplete]);

  // filter table by "Used my referral code" filter
  useEffect(() => {
    if (!filterValueUsedMyCode) {
      usedMyCodeFilter('');
      usedMyCodeMobileFilter('');
    } else {
      usedMyCodeFilter(true);
      usedMyCodeMobileFilter(true);
    }
  }, [filterValueUsedMyCode]);

  const filterControlUsedMyCode = (val) => {
    setFilterValueUsedMyCode(val);
  };

  useEffect(() => {
    sportNameFilter(sportNameFilterVal);
    positionFilter(positionFilterVal);
  }, [sportNameFilterVal, positionFilterVal]);

  // when sidebar filter value changes, set the sport and position column filter (hidden) values
  useEffect(() => {
    if (sidebarState === null || sidebarState === undefined) return;
    let filterVal = sidebarState.sport;

    if (sidebarState.gender) {
      if (sidebarState.gender === 'Mixed') {
        filterVal = sidebarState.gender + ' ' + filterVal;
      } else {
        filterVal = sidebarState.gender + "'s " + filterVal;
      }
    }
    setSportNameFilterVal(filterVal);
    setPositionFilterVal(sidebarState.position ?? '');
  }, [sidebarState]);

  // get data
  useEffect(() => {
    if (!user) return;

    // construct a list of this coach's sport names for the "shared with me" filter
    const coach_sports = user.sports.map((sport) => {
      return sport.name;
    });

    // find the coach's school id
    let coach_school = null;
    if (
      user.schools_shared_to !== undefined &&
      user.schools_shared_to.length > 0
    ) {
      coach_school = user.schools_shared_to[0].id;
    }
    if (coach_school === null) return;

    axios
      .get(`${apiConfig.baseUrl}/schools/${coach_school}/users/`, {
        auth: apiConfig.auth,
      })
      .then((res) => {
        let resdata = res.data;

        // if the coach has sports assigned, only show athletes for those
        // sports and delete others; otherwise show all athlets.
        if (coach_sports.length > 0) {
          resdata = resdata.filter((elem) => {
            return coach_sports.includes(elem.sport_name);
          });
        }

        _.forEach(resdata, (elem) => {
          let report_date_formatted =
            elem.initial_assessment_completion_datetime;
          if (report_date_formatted !== null) {
            report_date_formatted = new Date(
              report_date_formatted
            ).toLocaleDateString();
          }
          elem.report_date_formatted = report_date_formatted;

          elem.sport_combined = elem.sport_name + '\n' + elem.position_name;

          elem.name = elem.name + '\n' + elem.email;
          elem.combined_data =
            elem.name + '\n' + elem.sport_name + '\n' + elem.position_name;

          if (elem.initial_assessment_id) elem.combined_data += 'has_report';

          elem.is_my_sport = coach_sports.includes(elem.sport_name);
          elem.used_my_code = elem.coach_referral_coach === user.id;

          // create teams list
          elem.teams =
            elem.highschool_team +
            '\n' +
            elem.aau_team +
            '\n' +
            elem.travel_team;

          // add teams to combined data for mobile display
          if (elem.highschool_team || elem.aau_team || elem.travel_team) {
            elem.combined_data = elem.combined_data +=
              '\n' +
              '**Teams**' +
              '\n' +
              elem.highschool_team +
              '\n' +
              elem.aau_team +
              '\n' +
              elem.travel_team;
          }

          // set results URL
          elem.results_url = '';
          if (elem.initial_assessment_id) {
            elem.results_url = `/results/${elem.id}/${elem.initial_assessment_id}/`;
          }
        });
        setData(resdata);
      });
  }, [user]);

  const Filters = () => (
    <div style={{ float: 'left', marginBottom: 10 }}>
      {
        /* "My Athletes" filter has been removed, but keeping code in case it's added back */
        false && (
          <Form.Check
            type="switch"
            id="filter-control-sharedwithme"
            data-testid="coachportalathletelisting-filter-myathletes"
            label="My athletes"
            onClick={() => filterControlSharedWithMe(!filterValueSharedWithMe)}
            checked={filterValueSharedWithMe}
            onChange={() => {}}
          />
        )
      }
      <Form.Check
        type="switch"
        id="filter-control-usedmycode"
        label="Used my referral code"
        onClick={() => filterControlUsedMyCode(!filterValueUsedMyCode)}
        checked={filterValueUsedMyCode}
        onChange={() => {}}
      />
      <Form.Check
        type="switch"
        id="filter-control-assessmentcomplete"
        label="Completed results"
        onClick={() =>
          filterControlAssessmentComplete(!filterValueAssessmentComplete)
        }
        checked={filterValueAssessmentComplete}
        onChange={() => {}}
      />
    </div>
  );

  return (
    <div data-testid="coachportalathletelisting-container">
      <h2>Athlete Listing</h2>
      <CoachPortalBreadcrumbs />
      <div className="coach-portal-athlete-listing">
        <div className="d-none d-md-block">
          <ToolkitProvider keyField="id" data={data} columns={columns} search>
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} />
                <Filters />
                <BootstrapTable
                  {...props.baseProps}
                  filter={filterFactory()}
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <div className="mobile-view d-md-none">
          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns_mobile}
            search
          >
            {(props) => (
              <div>
                <Filters />
                <SearchBar {...props.searchProps} />
                <BootstrapTable
                  {...props.baseProps}
                  filter={filterFactory()}
                  pagination={paginationFactory()}
                  rowEvents={rowEventsMobile}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </div>
  );
};
CoachPortalAthleteListing.propTypes = {
  searchProps: PropTypes.object,
  baseProps: PropTypes.object,
};
export default CoachPortalAthleteListing;
