import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import Spinner from '../assets/Spinner';
import PortalPage from './pages/PortalPage';
import ProfilePage from './pages/ProfilePage';
import ResultPage from './pages/ResultPage';
import ResultPageRestricted from './pages/ResultPageRestricted';

/* LBP */
const AthleteRegistrationPage = lazy(() =>
  import('./pages/AthleteRegistrationPage')
);

const AssessmentPage = lazy(() => import('./pages/AssessmentPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const LogoutPage = lazy(() => import('./pages/LogoutPage'));
const FakeAfterLoginPage = lazy(() => import('./pages/FakeAfterLoginPage'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const CoachRegistrationPage = lazy(() =>
  import('./pages/CoachRegistrationPage')
);

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          {/* LBP Routes */}
          <Route path="/portal" component={PortalPage} />{' '}
          <Route path="/afterlogin" component={FakeAfterLoginPage} />{' '}
          <Route path="/signin" component={LoginPage} />{' '}
          <Route path="/logout" component={LogoutPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />{' '}
          <Route path="/forgot-password/" component={ForgotPasswordPage} />{' '}
          <Route path="/resetpassword" component={ResetPasswordPage} />{' '}
          <Route path="/assessment" component={AssessmentPage} />
          <Route path="/results/:athleteId/:resultId" component={ResultPage} />
          <Route path="/r/:directLinkId" component={ResultPageRestricted} />
          <Route path="/results" component={ResultPage} />
          <Route path="/register/coach" component={CoachRegistrationPage} />
          <Route path="/register/coach/" component={CoachRegistrationPage} />
          <Route path="/register" component={AthleteRegistrationPage} />
          <Route path="/register/" component={AthleteRegistrationPage} />
          <Route path="/profile/" component={ProfilePage} />
          <Route path="/" component={LoginPage} />
          {/* root url has to go last */}
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
