/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Card,
  Col,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import ls from 'local-storage';
import Cookie from 'js-cookie';

import logo from './images/logo.svg';
import { apiConfig } from '../apiConfig';
import UserContext from '../context/UserContext';
import CoachPortalSidebarContext from '../context/CoachPortalSidebarContext';
import Sidebar from './Sidebar';

function LoggedInLayout(props) {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [welcomeMsg, setWelcomeMsg] = useState('Hello!');
  const [sidebarState, setSidebarState] = useState(null);

  useEffect(() => {
    if (user) {
      setWelcomeMsg(`Hello, ${user.first_name}!`);
    }
  }, [user]);

  const token = ls.get('token');

  const logout = () => {
    axios
      .delete(apiConfig.baseUrl + `/token/delete/${token}/`, {
        auth: apiConfig.auth,
      })
      .finally(() => {
        // we use finally instead of then because we want to logout whether the axios call was successful or not
        ls.clear();
        Cookie.remove('token', {
          domain: '.leftbrainperformance.com',
        });

        history.push('/signin');
      });
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="md"
        bg="white"
        variant="light"
        style={{ zIndex: 999 }}
      >
        <Container fluid>
          <Navbar.Brand href="https://leftbrainperformance.com">
            <img
              src={logo}
              width="200"
              className="d-inline-block align-top"
              alt="leftBrain Performance logo"
              style={{ margin: 10, marginLeft: 0 }}
            />
          </Navbar.Brand>
          {props.restricted ? (
            <Link to="/signin">Sign In</Link>
          ) : (
            <>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-end"
              >
                <Nav className="d-none d-md-flex">
                  <NavDropdown
                    alignRight={true}
                    title={welcomeMsg}
                    id="collapsible-nav-dropdown"
                  >
                    {user && user.type === 'COACH' ? (
                      <></>
                    ) : user &&
                      user.type === 'ATHLETE' &&
                      user.completed_initial_assessment ? (
                      <NavDropdown.Item href="/results">
                        View Results
                      </NavDropdown.Item>
                    ) : (
                      <NavDropdown.Item href="/assessment">
                        Take Assessment
                      </NavDropdown.Item>
                    )}

                    <NavDropdown.Item href="/profile">
                      My Profile
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      onClick={() => {
                        logout();
                      }}
                    >
                      Signout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Nav className="d-md-none">
                  {user && user.type === 'COACH' ? (
                    <>
                      <Nav.Item>
                        <Nav.Link href="/portal">Athlete Listing</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        {user.completed_initial_assessment ? (
                          <Nav.Link href="/results">My Report</Nav.Link>
                        ) : (
                          <Nav.Link href="/assessment">
                            Take Assessment
                          </Nav.Link>
                        )}
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="/profile">
                          Find My Referral Link
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  ) : user && user.completed_initial_assessment ? (
                    <Nav.Item>
                      <Nav.Link href="/results">View Results</Nav.Link>
                    </Nav.Item>
                  ) : (
                    <Nav.Item>
                      <Nav.Link href="/assessment">Take Assessment</Nav.Link>
                    </Nav.Item>
                  )}

                  <Nav.Item>
                    <Nav.Link href="/profile">My Profile</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        logout();
                      }}
                    >
                      Signout
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </>
          )}
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <CoachPortalSidebarContext.Provider
            value={{ sidebarState, setSidebarState }}
          >
            {!props.restricted && <Sidebar />}

            <Col
              md={props.restricted || !props.isCoach ? 12 : 9}
              xl={props.restricted || !props.isCoach ? 12 : 10}
              className="ml-sm-auto"
            >
              <Card className="mainCard p-3 p-md-5 mx-sm-4 mx-lg-5 my-3 my-sm-4 my-lg-5">
                {props.children}
              </Card>
            </Col>
          </CoachPortalSidebarContext.Provider>
        </Row>
      </Container>
    </>
  );
}

export default LoggedInLayout;
