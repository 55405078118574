import React, { useContext } from 'react';

import PayMessage from '../components/PayMessage';
import UserContext from '../context/UserContext';
import { useHistory } from 'react-router-dom';
import UnapprovedMessage from '../components/UnapprovedMessage';
import CoachPortalAthleteListing from '../components/CoachPortalAthleteListing';

const PortalPage = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();

  if (user && user.has_paid && user.type !== 'COACH') {
    if (user.completed_initial_assessment === false) {
      history.push('/assessment');
    } else {
      history.push('/results');
    }
  }

  const CoachPortalView = () => {
    return (
      <>
        {user.coach_extension.is_approved ? (
          <CoachPortalAthleteListing />
        ) : (
          <UnapprovedMessage />
        )}
      </>
    );
  };

  return (
    <div>
      {user && !user.has_paid && user.type !== 'COACH' && (
        <PayMessage user={user} />
      )}
      {user && user.type === 'COACH' && <CoachPortalView />}
    </div>
  );
};

export default PortalPage;
